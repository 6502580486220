var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("e-CAC - DCTF"),
      ]),
      _c("DctfFilter"),
      _c(
        "v-card",
        { staticClass: "mt-4" },
        [
          _c("v-data-table", {
            staticClass: "table-dctf",
            attrs: {
              headers: _vm.headers,
              items: _vm.listDctf,
              options: _vm.pagination,
              "server-items-length": _vm.totalDctf,
              loading: _vm.loading,
              "sort-by": ["periodoApur"],
              "footer-props": {
                "items-per-page-options": [5, 25, 50],
              },
              "show-expand": "",
            },
            on: {
              "update:options": function ($event) {
                _vm.pagination = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.debitoApur",
                fn: function ({ item }) {
                  return [
                    _c("td", { staticClass: "bgColorRed" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("emptyCurrency")(
                            _vm._f("money")(item.debitoApur)
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.totalCompensacao",
                fn: function ({ item }) {
                  return [
                    _c("td", { staticClass: "bgColorGreen" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("emptyCurrency")(
                            _vm._f("money")(item.totalCompensacao)
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.totalPagamentos",
                fn: function ({ item }) {
                  return [
                    _c("td", { staticClass: "bgColorGreen" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("emptyCurrency")(
                            _vm._f("money")(item.totalPagamentos)
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.saldoPagar",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("emptyCurrency")(
                            _vm._f("money")(item.saldoPagar)
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "px-2 py-4 grey lighten-2",
                        attrs: { colspan: "6" },
                      },
                      [
                        _c(
                          "tr",
                          {
                            staticClass:
                              "v-data-table v-data-table--dense theme--light",
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass:
                                  "bgColorRed text-center col total-credito",
                              },
                              [
                                _vm._v(" Débito apurado "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.isVisibleTotalCreditos()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.showTotalCreditos,
                                            expression: "!showTotalCreditos",
                                          },
                                        ],
                                      },
                                      [_vm._v(" mdi-plus ")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showTotalCreditos,
                                            expression: "showTotalCreditos",
                                          },
                                        ],
                                      },
                                      [_vm._v(" mdi-minus ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "bgColorGreen text-center col total-credito",
                              },
                              [
                                _vm._v(" Crédito apurado "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.isVisibleTotalCreditos()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.showTotalCreditos,
                                            expression: "!showTotalCreditos",
                                          },
                                        ],
                                      },
                                      [_vm._v(" mdi-plus ")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showTotalCreditos,
                                            expression: "showTotalCreditos",
                                          },
                                        ],
                                      },
                                      [_vm._v(" mdi-minus ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "bgColorYellow text-center col total-credito",
                              },
                              [
                                _vm._v(" Outros créditos "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { "x-small": "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.isVisibleTotalCreditos()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.showTotalCreditos,
                                            expression: "!showTotalCreditos",
                                          },
                                        ],
                                      },
                                      [_vm._v(" mdi-plus ")]
                                    ),
                                    _c(
                                      "v-icon",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.showTotalCreditos,
                                            expression: "showTotalCreditos",
                                          },
                                        ],
                                      },
                                      [_vm._v(" mdi-minus ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.showTotalOutrosCreditos || _vm.showTotalCreditos
                          ? _c(
                              "tr",
                              {
                                staticClass:
                                  "v-data-table v-data-table--dense theme--light",
                                staticStyle: {
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _c(
                                  "td",
                                  { staticClass: "bgColorRed text-center col" },
                                  [
                                    _vm._v(
                                      "Total: " +
                                        _vm._s(
                                          _vm._f("emptyCurrency")(
                                            _vm._f("money")(_vm.debitoApur)
                                          )
                                        )
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass: "bgColorGreen text-center col",
                                  },
                                  [
                                    _vm._v(
                                      "Total: " +
                                        _vm._s(
                                          _vm._f("emptyCurrency")(
                                            _vm._f("money")(
                                              _vm.totalCreditosApurados
                                            )
                                          )
                                        )
                                    ),
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "bgColorYellow text-center col",
                                  },
                                  [
                                    _vm._v(
                                      "Total: " +
                                        _vm._s(
                                          _vm._f("emptyCurrency")(
                                            _vm._f("money")(
                                              _vm.totalOutrosCreditos
                                            )
                                          )
                                        )
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("DctfDetalhe", {
                          attrs: { id: item.id },
                          on: { items: _vm.getItems },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }