<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">e-CAC - DCTF</h2>

    <DctfFilter />
    <v-card class="mt-4">
      <v-data-table
        :headers="headers"
        :items="listDctf"
        :options.sync="pagination"
        :server-items-length="totalDctf"
        :loading="loading"
        :sort-by="['periodoApur']"
        :footer-props="{
          'items-per-page-options': [5, 25, 50],
        }"
        class="table-dctf"
        show-expand
      >
        <template v-slot:item.debitoApur="{ item }">
          <td class="bgColorRed">{{ item.debitoApur | money | emptyCurrency }}</td>
        </template>
        <template v-slot:item.totalCompensacao="{ item }">
          <td class="bgColorGreen">{{ item.totalCompensacao | money | emptyCurrency }}</td>
        </template>
        <template v-slot:item.totalPagamentos="{ item }">
          <td class="bgColorGreen">{{ item.totalPagamentos | money | emptyCurrency }}</td>
        </template>
        <template v-slot:item.saldoPagar="{ item }">
          {{ item.saldoPagar | money | emptyCurrency }}
        </template>

        <template v-slot:expanded-item="{ item }">
          <td colspan="6" class="px-2 py-4 grey lighten-2">
            <tr
              style="display: flex; flex-wrap: wrap; justify-content: center"
              class="v-data-table v-data-table--dense theme--light"
            >
              <td class="bgColorRed text-center col total-credito">
                Débito apurado
                <v-btn x-small @click="isVisibleTotalCreditos()">
                  <v-icon v-show="!showTotalCreditos"> mdi-plus </v-icon>
                  <v-icon v-show="showTotalCreditos"> mdi-minus </v-icon>
                </v-btn>
              </td>
              <td class="bgColorGreen text-center col total-credito">
                Crédito apurado
                <v-btn x-small @click="isVisibleTotalCreditos()">
                  <v-icon v-show="!showTotalCreditos"> mdi-plus </v-icon>
                  <v-icon v-show="showTotalCreditos"> mdi-minus </v-icon>
                </v-btn>
              </td>
              <td class="bgColorYellow text-center col total-credito">
                Outros créditos
                <v-btn x-small @click="isVisibleTotalCreditos()">
                  <v-icon v-show="!showTotalCreditos"> mdi-plus </v-icon>
                  <v-icon v-show="showTotalCreditos"> mdi-minus </v-icon>
                </v-btn>
              </td>
            </tr>
            <tr
              v-if="showTotalOutrosCreditos || showTotalCreditos"
              style="display: flex; flex-wrap: wrap; justify-content: center"
              class="v-data-table v-data-table--dense theme--light"
            >
              <td class="bgColorRed text-center col">Total: {{ debitoApur | money | emptyCurrency }}</td>
              <td class="bgColorGreen text-center col">Total: {{ totalCreditosApurados | money | emptyCurrency }}</td>
              <td class="bgColorYellow text-center col">Total: {{ totalOutrosCreditos | money | emptyCurrency }}</td>
            </tr>

            <DctfDetalhe :id="item.id" @items="getItems" />
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import ECacService from '@/services/ecacService.js';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'dctf',

  components: {
    DctfFilter: () => import('./components/DctfFilter.vue'),
    DctfDetalhe: () => import('./components/DctfDetalhe.vue'),
  },

  data() {
    return {
      headers: [
        { text: 'Período de apuração', width: '30%', value: 'periodoApur', align: 'center' },
        { text: 'Débito apurado', width: '20%', value: 'debitoApur', align: 'center' },
        { text: 'Crédito vinculado', width: '40%', value: 'totalCompensacao', align: 'center' },
        { text: 'Saldo a pagar', width: '10%', value: 'saldoPagar', align: 'right' },
      ],

      items: [],
      listDcompDctfDetalhe: [],
      showTotalCreditos: false,
      showTotalOutrosCreditos: false,
      totalCreditosApurados: 0,
      totalOutrosCreditos: 0,
    };
  },

  computed: {
    ...mapState('ecac', {
      pagination: (state) => state.pagination,
      listDctf: (state) => state.listDctf,
      totalDctf: (state) => state.totalDctf,
      loading: (state) => state.loading,
    }),

    pagination: {
      get: function () {
        return this.$store.state.ecac.pagination;
      },

      set: function (value) {
        this.changePagination(value);
      },
    },
  },

  // mounted() {
  //   this.fetch();
  // },

  methods: {
    ...mapActions('ecac', ['changePagination']),
    fillDcompDctfDetalhe(row) {
      this.listDcompDctfDetalhe = row.listDcompDctfDetalhe;
    },

    // async fetch() {
    //   let response = await ECacService.findDCTF();
    //   this.items = response.data.content;
    // },

    openDetalhe(item) {
      this.$refs.ModalDctfDetalhe.$emit('open', item.id);
    },

    async showDcomp(item) {
      this.listforDcomp = [];

      let response = await ECacService.getDcomps(item.id);

      // this.listforDcomp = response.data;

      let itemDcomp = response.data.content;

      let listDcomp = itemDcomp.map((element) => element.nrDcomp);

      this.listforDcomp = [{ id: item.totalDescricao, dcomps: listDcomp }];

      this.dcompDialog = true;
    },

    showPagamento(item) {
      this.listPagamentos = [];

      let listPagamentos = item.listDctfPagamento;

      this.listForPagamentos = listPagamentos;

      this.pagamentosDialog = true;
    },

    showSuspensao(item) {
      this.listSuspensao = [];

      let listSuspensao = item.listDctfSuspensao;

      this.listForSuspensao = listSuspensao;

      this.suspensaoDialog = true;
    },

    fillListdctfDetalhe(item) {
      this.dctfDetalhes = [];
      let dctfDetalhe = item.listDctfDetalhe;

      let keys = [];
      let listadoDctfSegurados = [];
      let listadoDctfCotapatronal = [];
      let listadoDctfOutrasEntidades = [];

      //Headers tabela de totais
      this.totalDescricao = item.totalDescricao;
      this.debitoApur = item.debitoApur;
      this.totalSalarioFamilia = item.totalSalarioFamilia;
      this.totalSalarioMaternidade = item.totalSalarioMaternidade;
      this.totalCompensacao = item.totalCompensacao;
      this.totalSuspensao = item.totalSuspensao;
      this.totalRetencao = item.totalRetencao;

      Object.entries(dctfDetalhe).forEach(([key, entries]) => {
        keys.push(key);
        if (entries.tipoNaturezaContribuicao === 'SEGURADOS') {
          listadoDctfSegurados.push(entries);
        } else if (entries.tipoNaturezaContribuicao === 'COTAPATRONAL') {
          listadoDctfCotapatronal.push(entries);
        } else if (entries.tipoNaturezaContribuicao === 'OUTRASENTIDADES') {
          listadoDctfOutrasEntidades.push(entries);
        }
      });

      this.popularHeadersSegurados(listadoDctfSegurados);
      this.popularHeadersCota(listadoDctfCotapatronal);
      this.popularHeadersOutros(listadoDctfOutrasEntidades);

      this.tableDataSegurados = listadoDctfSegurados;
      this.tableDataCotaPatronal = listadoDctfCotapatronal;
      this.tableDataOutrasEntidades = listadoDctfOutrasEntidades;

      this.expanded = item === this.expanded[0] ? [] : [item];
    },

    popularHeadersSegurados(listadoDctfSegurados) {
      this.totalApuradoSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.debitoApur)),
      );

      this.totalSalarioFamiliaSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.deducaoSalFamilia)),
      );

      this.totalSalarioMaternidadeSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.deducaoSalMaternidade)),
      );
      this.totalCompensacaoSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.deducaoCompensacao)),
      );

      this.totalSuspensaoSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.deducaoSuspensao)),
      );

      this.totalRetencaoSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.deducaoRetencao)),
      );
    },

    popularHeadersCota(listadoDctfCotapatronal) {
      this.totalApuradoCota = this.formatToBrCurrency(
        listadoDctfCotapatronal.map((element) => this.formatToFloat(element.debitoApur)),
      );

      this.totalSalarioFamiliaCota = this.formatToBrCurrency(
        listadoDctfCotapatronal.map((element) => this.formatToFloat(element.deducaoSalFamilia)),
      );

      this.totalSalarioMaternidadeCota = this.formatToBrCurrency(
        listadoDctfCotapatronal.map((element) => this.formatToFloat(element.deducaoSalMaternidade)),
      );
      this.totalCompensacaoCota = this.formatToBrCurrency(
        listadoDctfCotapatronal.map((element) => this.formatToFloat(element.deducaoCompensacao)),
      );

      this.totalSuspensaoCota = this.formatToBrCurrency(
        listadoDctfCotapatronal.map((element) => this.formatToFloat(element.deducaoSuspensao)),
      );

      this.totalRetencaoCota = this.formatToBrCurrency(
        listadoDctfCotapatronal.map((element) => this.formatToFloat(element.deducaoRetencao)),
      );
    },

    popularHeadersOutros(listadoDctfOutrasEntidades) {
      this.totalApuradoOutras = this.formatToBrCurrency(
        listadoDctfOutrasEntidades.map((element) => this.formatToFloat(element.debitoApur)),
      );

      this.totalSalarioFamiliaOutras = this.formatToBrCurrency(
        listadoDctfOutrasEntidades.map((element) => this.formatToFloat(element.deducaoSalFamilia)),
      );
      this.totalSalarioMaternidadeOutras = this.formatToBrCurrency(
        listadoDctfOutrasEntidades.map((element) => this.formatToFloat(element.deducaoSalMaternidade)),
      );
      this.totalSuspensaoOutras = this.formatToBrCurrency(
        listadoDctfOutrasEntidades.map((element) => this.formatToFloat(element.deducaoSuspensao)),
      );
      this.totalCompensacaoOutras = this.formatToBrCurrency(
        listadoDctfOutrasEntidades.map((element) => this.formatToFloat(element.deducaoCompensacao)),
      );
      this.totalRetencaoOutras = this.formatToBrCurrency(
        listadoDctfOutrasEntidades.map((element) => this.formatToFloat(element.deducaoRetencao)),
      );
    },

    formatToBrCurrency(y) {
      let x = y.reduce((a, b) => a + b).toLocaleString('pt-BR');
      return x;
    },

    formatToFloat(x) {
      let formated;
      if (x !== null) {
        formated = parseFloat(x.replace('.', '').replace(',', '.'));
      } else {
        formated = 0;
      }
      return formated;
    },
    getSumTotalCreditos() {
      return this.totalCreditosApurados !== 0 || this.totalCreditosApurados !== null;
    },
    getSumTotalOutrosCreditos() {
      return this.totalOutrosCreditos !== 0 || this.totalOutrosCreditos != null;
    },
    isVisibleTotalCreditos() {
      this.showTotalCreditos = !this.showTotalCreditos;
      if (this.showTotalCreditos) {
        this.getSumTotalCreditos();
      }
    },
    showTotalDebitoApur() {
      return (this.totalApuradoSegurados = this.formatToBrCurrency(
        listadoDctfSegurados.map((element) => this.formatToFloat(element.debitoApur)),
      ));
    },
    getItems(value) {
      this.debitoApur = 0;
      this.deducaoSalFamilia = 0;
      this.deducaoSalMaternidade = 0;
      this.deducaoRetencao = 0;
      this.deducaoCompensacao = 0;
      this.deducaoSuspensao = 0;
      value.forEach((i) => (this.debitoApur += this.formatToFloat(i.debitoApur)));
      value.forEach((i) => (this.deducaoSalFamilia += this.formatToFloat(i.deducaoSalFamilia)));
      value.forEach((i) => (this.deducaoSalMaternidade += this.formatToFloat(i.deducaoSalMaternidade)));
      value.forEach((i) => (this.deducaoRetencao += this.formatToFloat(i.deducaoRetencao)));
      value.forEach((i) => (this.deducaoCompensacao += this.formatToFloat(i.deducaoCompensacao)));
      value.forEach((i) => (this.deducaoSuspensao += this.formatToFloat(i.deducaoSuspensao)));

      this.totalCreditosApurados =
        this.formatToFloat(this.deducaoCompensacao) + this.formatToFloat(this.deducaoSuspensao);
      this.totalOutrosCreditos =
        this.formatToFloat(this.deducaoSalFamilia) +
        this.formatToFloat(this.deducaoSalMaternidade) +
        this.formatToFloat(this.deducaoRetencao);
      this.debitoApur = this.formatToBrCurrency(this.formatToFloat(this.debitoApur));
      this.deducaoSalFamilia = this.formatToBrCurrency(this.formatToFloat(this.deducaoSalFamilia));
      this.deducaoSalMaternidade = this.formatToBrCurrency(this.formatToFloat(this.deducaoSalMaternidade));
      this.deducaoRetencao = this.formatToBrCurrency(this.formatToFloat(this.deducaoRetencao));
      this.deducaoCompensacao = this.formatToBrCurrency(this.formatToFloat(this.deducaoCompensacao));
      this.deducaoSuspensao = this.formatToBrCurrency(this.formatToFloat(this.deducaoSuspensao));
    },
  },
};
</script>

<style scoped>
.table-dctf >>> .v-data-table__expanded__row {
  background: var(--v-secondary-base) !important;
  /* color: white; */
}
.table-dctf >>> .v-data-table__expanded__row:hover {
  background: var(--v-secondary-base) !important;
  /* color: unset; */
}
.bgColorYellow {
  background-color: #ffeead !important;
  text-align: center !important;
}
.bgColorRed {
  background-color: #ffe3e3 !important;
  text-align: center !important;
}
.bgColorGreen {
  background-color: #c0f1cc !important;
  text-align: center !important;
}
.v-btn {
  background-color: transparent !important;
}

.total-credito {
}
</style>
